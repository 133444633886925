<template>
	<div>
		<b-modal
			id="modal-add-department"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			:hide-footer="!$can('create','department')"
			ok-variant="primary"
			centered
			size="md"
			:title="$t('Add department')"
			@ok="confirmAddDepartment"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addDepartment">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormDepartment"
				>
					<!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="departmentName"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="departmentData.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Description -->
					<b-form-group
						label-for="description"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Description') }}</label>
						<validation-provider
							#default="{ errors }"
							name="departmentDescription"
							rules="required"
						>
							<b-form-input
								id="description"
								v-model="departmentData.description"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import memberStoreModule from '@/views/apps/member/memberStoreModule'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
	},
	data() {
		return {
			departmentData: {
				name: '',
				description: '',
			},
		}
	},
	mounted() {
	},
	created() {
	},
	destroyed() {
	},
	methods: {
		validationFormDepartment() {
			this.$refs.addDepartment.validate().then(success => {
				if (success) {
					store.dispatch('app-member/addDepartment', this.departmentData)
						.then(response => {
							if (response.status === 200) {
								if (response.data.code === '00') {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$emit('refetch-data')
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
								this.$nextTick(() => {
									setTimeout(() => {
										this.$bvModal.hide('modal-add-department')
									}, '500')
									setTimeout(() => {
										this.resetModal()
									}, '1000')
								})
							}
						})
						.catch(error => {
							const { config, response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		resetModal() {
			this.departmentData.name = ''
			this.departmentData.description = ''
		},
		confirmAddDepartment(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormDepartment()
		}
	},
	setup() {
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)
		return {
		}
	},
}
</script>